<script setup lang="ts">
const isMobileRef = computed(() => {
  return useIsMobileState().value;
});

defineProps({
  propHideRightPanel: {
    type: Boolean,
    required: false,
    default: false
  },
  fullScreen: {
    type: Boolean,
    required: false,
    default: true
  }
});
</script>

<template>
  <div v-if="isMobileRef">
    <el-skeleton style="width: 240px; margin-top: 16px">
      <template #template>
        <el-skeleton-item variant="image" style="width: 200px; height: 140px" />
        <div style="padding: 14px">
          <el-skeleton-item variant="p" style="width: 50%" />
          <div
            style="
              display: flex;
              align-items: center;
              justify-items: space-between;
            "
          >
            <el-skeleton-item variant="text" style="margin-right: 16px" />
            <el-skeleton-item variant="text" style="width: 30%" />
          </div>
        </div>
      </template>
    </el-skeleton>
    <el-skeleton :rows="10" animated />
  </div>
  <div
    v-if="!isMobileRef"
    class="placeholderPc"
    :class="{ marginPlaceholderPc: !fullScreen }"
  >
    <div :class="propHideRightPanel?'hide':'left'">
      <div v-for="i in 3" :key="i" class="line1">
        <el-skeleton style="width: 240px">
          <template #template>
            <el-skeleton-item
              variant="image"
              style="width: 240px; height: 240px"
            />
            <div style="padding: 14px">
              <el-skeleton-item variant="p" style="width: 50%" />
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-items: space-between;
                "
              >
                <el-skeleton-item variant="text" style="margin-right: 16px" />
                <el-skeleton-item variant="text" style="width: 30%" />
              </div>
            </div>
          </template>
        </el-skeleton>
        <el-skeleton :rows="6" animated />
      </div>
    </div>
    <div v-if="!(propHideRightPanel == true)" class="right">
      <el-skeleton :rows="10" animated />
    </div>
  </div>
</template>

<style scoped>
.placeholderPc {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.marginPlaceholderPc {
  width: auto;
  margin-left: calc((100% - 1100px) / 2);
  margin-right: calc((100% - 1100px) / 2);
}

.placeholderPc .left {
  flex: 72%;
  background-color: #f6f8f7;
  padding: 40px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 16px;
}
.placeholderPc .hide {
  background-color: #f6f8f7;
  flex: 72%;
  margin: 16px calc((100% - 1100px) / 2) 20px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.placeholderPc .left .line1 {
  background-color: #f6f8f7;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.placeholderPc .right {
  flex: 28%;
  margin-left: 16px;
  padding: 20px 20px 0px 20px;
  background-color: #f6f8f7;
  height: 400px;
  margin-top: 16px;
}

@media screen and (max-width: 999px) {
  .placeholderPc {
    height: 100vh;
    width: 100vw;
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    padding: 20px;
    background-color: #f6f8f7;
  }

  .placeholderPc .left {
    flex: 100%;
    background-color: #f6f8f7;
    padding: 0px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .placeholderPc .right {
    display: none;
  }
}
</style>
